/**
 * Removes all object keys where the value is undefined
 * @param object The object to be cleaned
 * @returns A new object without undefined values
 */
export function pruneUndefined<T extends object>(object: T): Partial<T> {
  const copy = { ...object };
  Object.keys(copy).forEach((key) => {
    if (copy[key as keyof T] === undefined) {
      delete copy[key as keyof T];
    }
  });
  return copy;
}
